import ResortsServices from '@/modules/Resorts/Services/ResortsServices.js'
export default
    {
        get : async (uuid) => {
            try{
                const osm_token = await ResortsServices.getOsmToken(uuid);
                let token = osm_token.data.object.token
                if(!osm_token || token === ''){
                   return null;
                }
                return token
            }catch(err){
                throw err;
            }
        }
    }
    