import ResortsServices from '@/modules/Resorts/Services/ResortsServices.js'
import i18n from "@/i18n";

export let resortsActions = {
  updateRtdata: {
    title: 'resorts.updatedbs.title',
    drm: [
      { context: 'resorts', drm: 'updatertdata' }
    ],
    action: async (uuid) => {
      window.dispatchEvent(new CustomEvent('showSpinner'))
      let event;
      try {
        await ResortsServices.generateRtdata(uuid)
        event = new CustomEvent('notifySuccess', {
          detail: {
            title: i18n.t("shops.success"),
            message: i18n.t('rtdata.in_progress')
          }
        });
      } catch (e) {
        event = new CustomEvent('notifyError', {
          detail: {
            title: i18n.t("shops.error"),
            message: null
          }
        });
        console.error(e)
      }

      window.dispatchEvent(new CustomEvent('hideSpinner'))
      window.dispatchEvent(event)
    },
  },
  updatePois: {
    title: 'resorts.update-pois.title',
    drm: [
      { context: 'resorts', drm: 'osm' }
    ],
    action: async (uuid) => {
      window.dispatchEvent(new CustomEvent('showSpinner'))
      let event;
      try {
        await ResortsServices.generatePois(uuid)
        event = new CustomEvent('notifySuccess', {
          detail: {
            title: i18n.t("shops.success"),
            message: null
          }
        });
      } catch (e) {
        event = new CustomEvent('notifyError', {
          detail: {
            title: i18n.t("shops.error"),
            message: null
          }
        });
        console.error(e)
      }

      window.dispatchEvent(new CustomEvent('hideSpinner'))
      window.dispatchEvent(event)
    },
  }
}
