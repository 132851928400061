import {resortsRoutes} from '@/modules/Resorts/Routes/resortsRoutes.js'
import {routesByName} from '@/modules/Shared/Helpers/routesHelper.js'
import {resortsActions as actions} from "@/modules/Resorts/Actions/resortsActions";

const routes = routesByName(resortsRoutes)

export default [
  {
    title: "resorts.information.title",
    subtitle: "resorts.information.subtitle",
    items: [
      {
        icon: "snowreport",
        route: routes['snow-report'],
        features: ['is_ski_resort']
      },
      {
        icon: "webcams",
        route: routes['webcams']
      },
      {
        icon: "content",
        route: routes['content']
      },

      {
        icon: "tracks",
        route: routes['resort-tracks'],
        features: ['is_ski_resort']
      },
      {
        icon: "photos",
        route: routes['resort-photos'],
        features: ['is_ski_resort']
      },
      {
        icon: "urls",
        route: routes['urls']
      },
      {
        icon: "events",
        route: routes['ToEvents']
      },
      {
        icon: "service",
        route: routes['ToServices']
      },
      {
        icon: "zones",
        route: routes['zones']
      },
      {
        icon: "thrills",
        route: routes['resort-thrills']
      },
      {
        icon: "audiences",
        route: routes['resort-audiences']
      },
      {
        icon: "requirements",
        route: routes['resort-requirements']
      },
    ]
  },
  {
    title: "resorts.geo.title",
    subtitle: "resorts.geo.subtitle",
    items: [
      {
        icon: "pois",
        route: routes['pois']
      },
      {
        icon: "maps",
        route: routes['maps']
      },
    ]
  },
  {
    title: "resorts.settings.title",
    subtitle: "resorts.settings.subtitle",
    items: [
      {
        icon: "updateresortdata",
        action: actions.updateRtdata
      },
      {
        icon: "poisupdate",
        action: actions.updatePois
      },
    ]
  }
]
